import React, { useState, useEffect } from 'react';
import { Navigation } from './components/navigation';
import { Header } from './components/header';
import { About } from './components/about';
import { Services } from './components/services';
import { Gallery } from './components/gallery';
import { Contact } from './components/contact';
import JsonData from './data/data.json';
import SmoothScroll from 'smooth-scroll';
import './App.css';
import { Helmet } from 'react-helmet';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  return (
    <div>
      {landingPageData && landingPageData.Header && (
        <Helmet>
          <title>{landingPageData.Header.title}</title>
          <meta name="description" content={landingPageData.Header.paragraph} />
          <meta property="og:title" content={landingPageData.Header.title} />
          <meta
            property="og:description"
            content={landingPageData.Header.paragraph}
          />
          <meta
            property="og:image"
            content="https://amvrestoration.com/img/logo.png"
          />
          <meta property="og:url" content="https://amvrestoration.com/" />
          <meta property="og:type" content="website" />
        </Helmet>
      )}
      <Navigation />
      <Header data={landingPageData.Header} />
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      <Gallery data={landingPageData.Gallery} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;
